

























































































































import Vue from "vue";
import Component from "vue-class-component";
import { ImprovableArea } from "../../api/types";
import { improvement } from "../../store";
import {
  ImprovementActions,
  ImprovementMutations,
  ImprovementState,
} from "../../store/improvements/types";

@Component({})
export default class Toolbar extends Vue {
  @improvement.State(ImprovementState.currentMap) currentMap!: string;
  @improvement.State(ImprovementState.activeLayer) activeLayer!: string;
  @improvement.State(ImprovementState.activeArea) activeArea!: string;
  @improvement.State(ImprovementState.selectedKgs) selectedKgs!: ImprovableArea;
  @improvement.State(ImprovementState.kgsValues) kgsValues!: ImprovableArea[];

  @improvement.Action(ImprovementActions.updateActiveArea)
  updateActiveArea!: (val: string) => void;
  @improvement.Action(ImprovementActions.updateSelectedKgs)
  updateSelectedKgs!: (val: ImprovableArea) => void;
  @improvement.Action(ImprovementActions.setNextSelectedKgs)
  setNextSelectedKgs!: () => Promise<void>;
  @improvement.Action(ImprovementActions.setPrevSelectedKgs)
  setPrevSelectedKgs!: () => Promise<void>;

  @improvement.Mutation(ImprovementMutations.setCurrentMap)
  setCurrentMap!: (val: string) => void;
  @improvement.Mutation(ImprovementMutations.setActiveLayer)
  setActiveLayer!: (val: string) => void;

  get maps() {
    return [
      {
        text: this.$t("map.layer.iibmap"),
        value: "iibmap",
      },
      {
        text: this.$t("map.layer.iibsw"),
        value: "iibsw",
      },
      {
        text: this.$t("map.layer.osm"),
        value: "osm",
      },
    ];
  }

  get priceLayers() {
    return [
      {
        text: this.$t("map.layer.pricelayer_hk"),
        value: "pricelayer_hk",
      },
      {
        text: this.$t("map.layer.pricelayer_wk"),
        value: "pricelayer_wk",
      },
      {
        text: this.$t("map.layer.pricelayer_hm"),
        value: "pricelayer_hm",
      },
      {
        text: this.$t("map.layer.pricelayer_wm"),
        value: "pricelayer_wm",
      },
      {
        text: this.$t("map.layer.pricelayer_none"),
        value: "pricelayer_none",
      },
    ];
  }

  get updateKgs(): ImprovableArea {
    return this.selectedKgs;
  }

  set updateKgs(val: ImprovableArea) {
    this.updateSelectedKgs(val);
  }

  kgsValueFilter(item: ImprovableArea, queryText: string, itemText: string) {
    return (
      item.kgs.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
        -1 ||
      item.title.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    );
  }
}

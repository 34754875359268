








import Vue from "vue";
import Component from "vue-class-component";
import { State } from "vuex-class";
import { RootState } from "../../store";
@Component({})
export default class LoadingOverlay extends Vue {
  @State(RootState.loading)
  loading!: boolean;
}

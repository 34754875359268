var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar',{attrs:{"color":"transparent","flat":"","absolute":"","width":"100%"}},[_c('v-btn-toggle',{staticClass:"ml-10 mt-1",attrs:{"color":"primary"}},[_c('v-btn',{class:{ 'v-btn--active': _vm.activeArea === 'kgs8' },on:{"click":function($event){return _vm.updateActiveArea('kgs8')}}},[_vm._v(" KGS8 ")]),_c('v-btn',{class:{ 'v-btn--active': _vm.activeArea === 'kgs11' },on:{"click":function($event){return _vm.updateActiveArea('kgs11')}}},[_vm._v(" KGS11 ")]),_c('v-btn',{class:{ 'v-btn--active': _vm.activeArea === 'kgs14' },on:{"click":function($event){return _vm.updateActiveArea('kgs14')}}},[_vm._v(" KGS14 ")]),_c('v-btn',{class:{ 'v-btn--active': _vm.activeArea === 'kgs17' },on:{"click":function($event){return _vm.updateActiveArea('kgs17')}}},[_vm._v(" KGS17 ")])],1),_c('v-autocomplete',{staticClass:"ml-3 mt-1 mapit-autocomplete",attrs:{"background-color":"white","hide-details":"","height":"48","single-line":"","items":_vm.kgsValues,"filter":_vm.kgsValueFilter},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"text-button ml-3 primary--text",staticStyle:{"line-height":"20px"}},[_vm._v(" KGS ")])]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-button ml-3 primary--text",staticStyle:{"line-height":"20px"}},[_vm._v(" "+_vm._s(item.kgs)+" - "+_vm._s(item.title)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-button ml-3 primary--text",staticStyle:{"line-height":"20px"}},[_vm._v(" "+_vm._s(item.kgs)+" - "+_vm._s(item.title)+" ")])]}}]),model:{value:(_vm.updateKgs),callback:function ($$v) {_vm.updateKgs=$$v},expression:"updateKgs"}}),_c('v-btn-toggle',{staticClass:"ml-3 mt-1",attrs:{"color":"primary"}},[_c('v-btn',{on:{"click":_vm.setPrevSelectedKgs}},[_c('v-icon',[_vm._v("mdi-skip-previous-outline")])],1),_c('v-btn',{on:{"click":_vm.setNextSelectedKgs}},[_c('v-icon',[_vm._v("mdi-skip-next-outline")])],1)],1),_c('v-spacer'),_c('v-btn-toggle',{staticClass:"mt-1"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","width":200,"color":"primary"}},'v-btn',attrs,false),on),[(_vm.currentMap)?[_vm._v(" "+_vm._s(_vm.$t("map.layer." + _vm.currentMap))+" ")]:[_vm._v(" Kartenlayer ")]],2)]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.maps),function(map){return _c('v-list-item',{key:("notification-key-" + (map.value)),on:{"click":function($event){return _vm.setCurrentMap(map.value)}}},[_c('v-list-item-title',[_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(map.text))])])],1)}),1)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"width":200,"text":"","color":"primary"}},'v-btn',attrs,false),on),[(_vm.activeLayer)?[_vm._v(" "+_vm._s(_vm.$t("map.layer." + _vm.activeLayer))+" ")]:[_vm._v(" Preislayer ")]],2)]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.priceLayers),function(priceLayer){return _c('v-list-item',{key:("notification-key-" + (priceLayer.value)),on:{"click":function($event){return _vm.setActiveLayer(priceLayer.value)}}},[_c('v-list-item-title',[_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(priceLayer.text))])])],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }













































































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import L, { DragEndEvent, LatLng, LatLngBounds, icon } from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LControlLayers,
  LGeoJson,
  LPopup,
} from "vue2-leaflet";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import VGeosearch from "vue2-leaflet-geosearch";

import LNonTiledLayerWMS from "../leaflet/LNonTiledLayerWMS.vue";
import { improvement } from "../../store";
import { AreaType, CenterPointDto } from "../../api/types";
import { latLngFromPoint } from "../../store/helpers";
import {
  ImprovementActions,
  ImprovementState,
} from "../../store/improvements/types";
import { cloneDeep, isEqual } from "lodash";

@Component({
  components: {
    LMap,
    LMarker,
    LTileLayer,
    LGeoJson,
    LControlLayers,
    LPopup,
    "l-wms-tile-layer": LNonTiledLayerWMS,
    VGeosearch,
  },
})
export default class MidpointImprovement extends Vue {
  $refs!: {
    map: LMap;
    cityOverlay: LGeoJson;
  };

  zoom = 12;
  geosearchOptions = {
    provider: new OpenStreetMapProvider(),
  };

  @improvement.State(ImprovementState.currentMap) currentMap!: string;
  @improvement.State(ImprovementState.activeLayer) activeLayer!: string;
  @improvement.State(ImprovementState.activeArea) activeArea!: AreaType;
  @improvement.State(ImprovementState.improvement)
  improvement!: CenterPointDto | undefined;

  @improvement.Action(ImprovementActions.updateCurrentPosition)
  updateCurrentPosition!: (payload: {
    position: LatLng;
    index: number;
  }) => void;
  @improvement.Action(ImprovementActions.confirmNewCentroid)
  confirmNewCentroid!: (payload: { index: number }) => void;
  @improvement.Action(ImprovementActions.load)
  load!: () => Promise<void>;
  @improvement.Action(ImprovementActions.setNextSelectedKgs)
  setNextSelectedKgs!: () => Promise<void>;
  @improvement.Action(ImprovementActions.setPrevSelectedKgs)
  setPrevSelectedKgs!: () => Promise<void>;

  wmsLayerBaseUrl = "https://map.iib-institut.de/atlas/service";
  tileLayers = [
    {
      name: "iibmap",
      layers: "iibmap",
      url: "https://api.mapbox.com/styles/v1/iibmaps/ckyirmvvuxj8q14pezcl6odmp/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiaWlibWFwcyIsImEiOiJjbDNpazhuZHEwMWR3M2l1dzA4Z3dhcW93In0.iM-jNwl44fyxWoAa7EB2WA",
      attribution: ` | Copyright(C) ${new Date().getFullYear()} by <a href="http://www.iib-institut.de/impressum/" target="_blank" title="Wohnlagenkarte">iib-institut.de</a>  © <a href="https://www.mapbox.com/about/maps" target="_blank">Mapbox</a>  © <a href="http://www.openstreetmap.org/about" target="_blank">OpenStreetMap</a>`,
    },
    {
      name: "iibsw",
      layers: "iibsw",
      url: "https://api.mapbox.com/styles/v1/iibmaps/cks7lry4327vm17p4s24u5spd/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiaWlibWFwcyIsImEiOiJjbDNpazhuZHEwMWR3M2l1dzA4Z3dhcW93In0.iM-jNwl44fyxWoAa7EB2WA",
      attribution: ` | Copyright(C) ${new Date().getFullYear()} by <a href="http://www.iib-institut.de/impressum/" target="_blank" title="Wohnlagenkarte">iib-institut.de</a>  © <a href="https://www.mapbox.com/about/maps" target="_blank">Mapbox</a>  © <a href="http://www.openstreetmap.org/about" target="_blank">OpenStreetMap</a>`,
    },
    {
      name: "osm",
      layers: "osm",
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    },
  ];
  iibmapLayers = [
    L.tileLayer("https://prod.gcp-maps.iib-it.de/areas/{z}/{x}/{y}.png", {
      tms: true,
      zIndex: 100,
    }), // areas
    L.tileLayer(
      "https://api.mapbox.com/styles/v1/iibmaps/ckv7tb68n8jzh15np8fd28vtv/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiaWlibWFwcyIsImEiOiJjbDNpazhuZHEwMWR3M2l1dzA4Z3dhcW93In0.iM-jNwl44fyxWoAa7EB2WA",
      { zIndex: 101 },
    ), // foreground
    L.tileLayer("https://prod.gcp-maps.iib-it.de/ratings/{z}/{x}/{y}.png", {
      tms: true,
      zIndex: 102,
    }), // ratings
  ];

  wmsLayers = [
    {
      name: "pricelayer_hk",
      layers: "pricelayer_hk",
      format: "image/png",
      transparent: true,
    },
    {
      name: "pricelayer_wk",
      layers: "pricelayer_wk",
      format: "image/png",
      transparent: true,
    },
    {
      name: "pricelayer_hm",
      layers: "pricelayer_hm",
      format: "image/png",
      transparent: true,
    },
    {
      name: "pricelayer_wm",
      layers: "pricelayer_wm",
      format: "image/png",
      transparent: true,
    },
  ];

  mapOptions = {
    zoomSnap: 0.5,
  };

  redIcon = icon({
    iconUrl: require("@/assets/red-marker.png"),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
  });

  async created() {
    await this.load();
  }

  mapReadyEvent() {
    const map = this.$refs.map.mapObject;

    if (this.improvement && this.improvement.centerPoints.length > 0) {
      // if there are already selected elements set bounding box around them
      const cityOverlay = this.$refs.cityOverlay;
      map.fitBounds(cityOverlay.getBounds() as any);
    } else {
      // when no previous bounds are set position to center of germany
      const initialBounds: LatLngBounds = new LatLngBounds([
        [51.133481, 10.018343],
      ]);
      map.fitBounds(initialBounds);
    }
    this.updateMapLayers();
  }

  get cityOverlay(): GeoJSON.MultiPolygon {
    if (this.improvement) {
      const overlay = cloneDeep(this.improvement.area);
      overlay.coordinates[0].unshift([
        [180, -90],
        [180, 90],
        [-180, 90],
        [-180, -90],
      ]);
      return overlay;
    }
    return {
      type: "MultiPolygon",
      coordinates: [
        [
          [
            [180, -90],
            [180, 90],
            [-180, 90],
            [-180, -90],
          ],
        ],
      ],
    };
  }

  postition(point: GeoJSON.Point): LatLng {
    return latLngFromPoint(point);
  }

  updateSize() {
    setTimeout(() => {
      this.$refs.map.mapObject.invalidateSize();
    }, 200);
  }

  closePopup() {
    const map = this.$refs.map.mapObject;
    map.closePopup();
  }

  async markerDragEnd(event: DragEndEvent, index: number) {
    const target = event.target as L.Marker;
    this.updateCurrentPosition({
      index,
      position: target.getLatLng(),
    });
    setTimeout(() => target.openPopup(), 50);
  }

  async saveNewCentroid(index: number) {
    const res = await this.$confirm(
      "Qualität wird auf 50 gesetzt. Eine Änderung mit MapIt ist danach nicht mehr möglich!",
      {
        title: "Aktuelle Position übernehmen?",
        buttonTrueText: "OK",
        buttonFalseText: "Abbrechen",
      },
    );

    await this.confirmNewCentroid({ index });
  }

  @Watch(ImprovementState.improvement, { deep: true })
  async improvementPointsWatch(
    newData?: CenterPointDto,
    oldData?: CenterPointDto,
  ) {
    const map = this.$refs.map.mapObject;
    if (newData && !isEqual(newData.area, oldData?.area)) {
      await this.$nextTick();
      const cityOverlay = this.$refs.cityOverlay;
      map.flyToBounds(cityOverlay.getBounds() as any, { duration: 0.3 });
    }
  }

  @Watch(ImprovementState.currentMap)
  updateMapLayers() {
    if (this.currentMap === "iibmap") {
      this.iibmapLayers.forEach((layer) =>
        this.$refs.map.mapObject.addLayer(layer),
      );
    } else {
      this.iibmapLayers.forEach((layer) =>
        this.$refs.map.mapObject.removeLayer(layer),
      );
    }
  }
}

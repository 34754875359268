












import Vue from "vue";
import Component from "vue-class-component";
import { State } from "vuex-class";
import { RootState } from "../../store";
import LoadingOverlay from "./LoadingOverlay.vue";
import MidpointImprovement from "./MidpointImprovement.vue";
import Toolbar from "./Toolbar.vue";
@Component({
  components: {
    LoadingOverlay,
    MidpointImprovement,
    Toolbar,
  },
})
export default class Layout extends Vue {
  @State(RootState.loading)
  loading!: boolean;
}
